function Home() {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold">Bienvenue sur le Dashboard</h1>
        <p>Ceci est la page d'accueil du Dashboard.</p>
      </div>
    );
  }
  
  export default Home;
  